import React, { PureComponent } from 'react'
import { join } from 'ramda'

import Masonry from 'react-masonry-component'

import '../assets/scss/Gallery.scss'

const masonryOptions = {
  transitionDuration: 0,
}

class Gallery extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      loadedImages: []
    }
  }

  componentWillMount() {
    this.loadImages(this.props, this.state)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadImages(this.props, prevState)
  }

  loadImages(props, state) {
    const { images } = props

    const preloadedImages = []
    images.forEach(({ preview, ID_image }, index) => {
        preloadedImages[index] = new Image()
        // Assigning the img src immediately requests the image
        preloadedImages[index].src = preview
    })
  }

  handleImageLoaded = (ID_image) => () => this.setState((prevState) =>  ({
    loadedImages: [...prevState.loadedImages, ID_image],
  }))

  render() {
    const {
      loadedImages
    } = this.state

    const {
      images, buttonActive
    } = this.props

    const childElements = images.map((image, index) => {
      const {
        file, preview, title, description, ID_image
      } = image

      const linkParams = {
        href: file,
        ['data-fancybox']: 'gallery'
      }
      if (title || description) {
        linkParams['data-caption'] = title || '' + ' ' + description || ''
      }

      const classes = [
        'animated',
        'slideInUp',
        'portfolio-item',
        'col-xs-12',
        'col-sm-6'
      ]

      const isBig = (index + 1) % 5 === 0 && index !== 0
      classes.push(isBig ? 'col-md 6 col-lg-4' : 'col-md-3 col-lg-2')

      const imgClasses = [
        'img-responsive',
        'item-img'
      ]
      if (loadedImages.includes(ID_image)) {
        imgClasses.push('loaded')
      }

      return (
        <article
          className={join(' ', classes)}
        >
          <a
            {...linkParams}
          >
            <img
              src={preview}
              onLoad={this.handleImageLoaded(ID_image)}
              className={join(' ', imgClasses)}
            />
            {(title || description) && (
              <div className='item-inner'>
                {title && (
                  <h3>{title}</h3>
                )}
                {description && (
                  <p>{description}</p>
                )}
              </div>
            )}
          </a>
        </article>
      )
    })

    return (
      <Masonry
        className={'portfolio-items row'} // default ''
        elementType={'div'} // default 'div'
        options={masonryOptions} // default {}
        onImagesLoaded={() => buttonActive()}
        //imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        {childElements}
      </Masonry>
    )
  }
}

export default Gallery
