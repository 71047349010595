import '../assets/scss/App.scss'
//import '../assets/scss/Bootstrap.scss'

import React, { PureComponent, Fragment } from 'react'
import { path, replace, isEmpty } from 'ramda'

import Gallery from './Gallery'
import { fetchApi } from '../module'
import Albums from './Albums'
import MobileDetect from 'mobile-detect'

class App extends PureComponent {
  constructor(props) {
    super(props)

    const detect = new MobileDetect(window.navigator.userAgent)

    let isMobile = false
    if (detect.mobile()) {
      isMobile = true
    }

    const pathName = path(['pathname'], new URL(window.location.href))
    const lang = pathName === '/' ? 'cs' : replace(/\//g, '', pathName)

    this.state = {
      albums: [],
      pagination: {},
      images: [],
      offset: 0,
      activeAlbum: 0,
      isMobile,
      showButton: false,
      lang
    }
  }

  componentDidMount() {
    this.fetchAlbums()
    this.fetchImages()
  }

  clearImages = () => {
    this.setState({
      pagination: {
        lastPage: true
      },
      images: [],
      offset: -12
    })
  }

  fetchImages = (ID_album = undefined, offset) => {
    const {
      isMobile
    } = this.state

    const imagesOffset = (offset === 0 ? offset : this.state.offset)

    const params = { offset: imagesOffset, isMobile: false }
    if (ID_album) {
      params.ID_album = ID_album
    }

    fetchApi('https://www.romanakroupova.com/api/v1/images', { ...params })
    //fetchApi('http://www.romanakroupova.loc/api/v1/images', { ...params })
      .then(response => response.json())
      .then(data => {
        this.setState((prevState) => ({
          pagination: data.pagination,
          images: [...prevState.images, ...data.images],
          offset: (offset === 0 ? offset : prevState.offset) + 12,
          showButton: false
        }))
      })
  }

  fetchAlbums = () => {
    fetchApi('https://www.romanakroupova.com/api/v1/albums', { lang: this.state.lang })
    //fetchApi('http://www.romanakroupova.loc/api/v1/albums')
      .then(response => response.json())
      .then(data => {
        const activeAlbum = data.albums[0].ID_album
        this.setState({
          albums: data.albums,
          activeAlbum
        })
      })
  }

  switchAlbum = (albumId) => {
    this.clearImages()
    this.fetchImages(albumId, 0)
    this.setState({
      activeAlbum: albumId
    })
  }

  buttonActive = () => this.setState({
    showButton: true
  })

  render() {
    const { pagination, albums, images, activeAlbum, showButton, lang } = this.state

    return (
      <Fragment>
        <Albums
          albums={albums}
          switchAlbum={this.switchAlbum}
          activeAlbum={activeAlbum}
        />
        <div className='container-fluid'>

          {images && images.length > 0 && (
            <Gallery
              images={images}
              buttonActive={this.buttonActive}
            />
          )}

          {showButton && !pagination.lastPage && (
          <div className='text-center'>
            <button
              className="btn btn-primary view-more-button"
              onClick={() => this.fetchImages(activeAlbum)}
            >
              <span>{lang === 'cs' ? 'Další fotky' : 'More photos'}</span>
            </button>
          </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default App
